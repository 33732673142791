import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/tsuto/webapp/pcrecommend/src/components/layout.js";
import Image from "components/image";
import { Link, graphql } from "gatsby";
import BasicTable from "components/basicTable";
import RaderChart from "components/raderChartLoadable";
import MakerRanking from "components/makerRankingLoadable";
import Socials from "components/socials";
import TOC from "components/toc";
import AccentBox from 'components/accentBox';
import SmallBox from 'components/smallBox';
import Lineup from 'components/lineup';
export const query = graphql`
  {
    allMdx(filter: {slug: {eq: "makers/mouse-computer"}}) {
      nodes {
        headings {
          depth
          value
        }
      }
    },
    mouse_wordcloud: file(relativePath: { eq: "pcmaker/mouse_wordcloud.png"}) { ...normalImg },
    mouse: file(relativePath: { eq: "pcmaker/mouse.png"}) { ...normalImg },
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`マウスコンピュータの評判 - コストパフォーマンスと国内生産の安定感`}</h1>
    <Socials {...props} mdxType="Socials" />
    <Image {...props} name="mouse_wordcloud" alt="マウスコンピュータの評判" fadeIn={false} mdxType="Image" />
    <p>{`マウスコンピュータは1998年に有限会社MCJとして設立されたパソコンメーカーであり、現在はBTOメーカーとしては国内で唯一東証に上場している。`}</p>
    <p>{`BTOとはBuild To Orderの略で、受注生産の意味である。すなわち購入者は部品スペックを指定して購入し、それに応じてパソコンを組み立てて納品するという形態であり、`}<strong parentName="p"><em parentName="strong">{`通常のメーカーと比べて不要機能を省きやすい`}</em></strong>{`ことから支持を得ている。`}</p>
    <p>{`そんなBTOメーカーであるマウスコンピュータの評判ってどうなのよ？という問いに本記事では答えていく。尚、他メーカーと比較検討したい場合は`}<Link to="/pcmaker/" mdxType="Link">{`パソコンメーカーおすすめランキング`}</Link>{`も併せて参照して欲しい。`}</p>
    <TOC {...props} mdxType="TOC" />
    <h2 {...{
      "id": "マウスコンピュータのラインナップと特徴",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%81%AE%E3%83%A9%E3%82%A4%E3%83%B3%E3%83%8A%E3%83%83%E3%83%97%E3%81%A8%E7%89%B9%E5%BE%B4",
        "aria-label": "マウスコンピュータのラインナップと特徴 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスコンピュータのラインナップと特徴`}</h2>
    <Lineup {...props} maker="mouse" mdxType="Lineup" />
    <p>{`ラインナップとしてはタワー型と呼ばれる`}<strong parentName="p"><em parentName="strong">{`大型のデスクトップパソコンや14インチ以上のノートパソコンをハイエンドからミドルエンドまで幅広く扱っている`}</em></strong>{`。一方デスクトップでは一体型パソコン、ノートパソコンでは13.3インチ以下のモバイルノートは扱っていない。`}</p>
    <p>{`小型化やハードウェアに強みを持つNEC、富士通、Dell、Lenovoなどの国内・海外大手が得意とする領域とはバッティングしないような製品群となっている。`}</p>
    <p>{`ブランドは細かく分かれており、`}<strong parentName="p"><em parentName="strong">{`スタンダードなMouse、ゲーミングブランドのG-Tune、クリエイターブランドのDAIV、法人向けのMouse Pro`}</em></strong>{`となっている。`}</p>
    <AccentBox title="サブブランド" mdxType="AccentBox">
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902210" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902210" height="1" width="1" border="0" alt="" />G-Tune</a>: マウスのゲーミングブランド。ハイスペックタワー型から軽量10時間駆動ノートなど製品力も高い。</li>
  <li><a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902208" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902208" height="1" width="1" border="0" alt="" />DAIV</a>: クリエイター向けのブランド。Adobe After Effectsを使用する人や3Dモデリングを行う人などプロのクリエイティブ作業に最適なモデル。</li>
    </AccentBox>
    <div align="right">>> <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt="" />ラインナップを見る</a></div>
    <h2 {...{
      "id": "マウスコンピュータの評判、総評と項目別評価",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%81%AE%E8%A9%95%E5%88%A4%E3%80%81%E7%B7%8F%E8%A9%95%E3%81%A8%E9%A0%85%E7%9B%AE%E5%88%A5%E8%A9%95%E4%BE%A1",
        "aria-label": "マウスコンピュータの評判、総評と項目別評価 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスコンピュータの評判、総評と項目別評価`}</h2>
    <RaderChart maker='mouse' className={'top-margin'} mdxType="RaderChart" />
    <p>{`マウスコンピュータはもともとマニアやパソコン好きの人が客層であったものの、乃木坂のCMなどを機に一般層にターゲットを広げ、売り上げが急増した。`}</p>
    <p>{`パソコンの初心者にまでマウスコンピュータの認知が広がったことと、販売規模が拡大したことにより`}<strong parentName="p"><em parentName="strong">{`24時間の電話対応や、修理も含めた強固なサポート体制というマウスクオリティ－`}</em></strong>{`が整った。`}</p>
    <p>{`コスパでは必ずしもデルやLenovoといった海外メーカーには敵わないものの、`}<strong parentName="p"><em parentName="strong">{`国内生産を基盤とするサポートの安定感や納期、ブランド力により、当サイトでは全メーカー中トップの売上`}</em></strong>{`を誇っている。`}</p>
    <AccentBox title="マウスコンピュータの項目別評価" mdxType="AccentBox">
  <li><span className="bold">コストパフォーマンス</span>・・・海外メーカーにやや劣るが十分にコスパが高い</li>
  <li><span className="bold">サポート</span>・・・<a href="https://www2.mouse-jp.co.jp/ssl/user_support2/sc_index.asp" target="_blank" rel="nofollow noopenner">サポートサイト</a>より、24時間365日の電話サポート、および72時間以内の修理体制はBTOメーカーでは随一</li>
  <li><span className="bold">デザイン</span>・・・たまにカッコいいモデルも見つかる程度</li>
  <li><span className="bold">人気</span>・・・当サイトでの人気。最も売れるパソコンメーカー、ゲーミングからスタンダードノートまで満遍なく売れている</li>
  <li><span className="bold">安定性</span>・・・上場企業であり業績も好調。安定した運営が見込める</li>
    </AccentBox>
    <h2 {...{
      "id": "実際に売れているモデルはこれ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%AE%9F%E9%9A%9B%E3%81%AB%E5%A3%B2%E3%82%8C%E3%81%A6%E3%81%84%E3%82%8B%E3%83%A2%E3%83%87%E3%83%AB%E3%81%AF%E3%81%93%E3%82%8C",
        "aria-label": "実際に売れているモデルはこれ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`実際に売れているモデルはこれ`}</h2>
    <p>{`本サイトを経由して実際によく売れているモデルを紹介する。当サイトはパソコンにそれほど詳しくはないが、インターネットで買い物ができるなど標準的なITリテラシーを持った人をメインターゲットとしており、比較して堅実に買い物をしている人が多いと思われる。そのような層で人気が高いパソコンは下記の２つである。`}</p>
    <h3 {...{
      "id": "156インチ-スタンダードノート",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#156%E3%82%A4%E3%83%B3%E3%83%81-%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%80%E3%83%BC%E3%83%89%E3%83%8E%E3%83%BC%E3%83%88",
        "aria-label": "156インチ スタンダードノート permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`15.6インチ スタンダードノート`}</h3>
    <p>{`ノートパソコンで最も普及しているのが15.6インチのパソコンである。BTOメーカーはパソコン好きな層が多く、基本的にはデスクトップパソコンが良く売れるが、`}<strong parentName="p"><em parentName="strong">{`マウスに関しては15.6インチのノートが恐ろしく売れている`}</em></strong>{`。`}</p>
    <p>{`オーソドックスに家で安心して使えるパソコンということで、ITリテラシーがそれなりに高くネットでの購入に躊躇がない人が、NECや富士通のパソコンを買う代わりにこちらを選んでいるという印象である。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=887394567&vc_url=https%3A%2F%2Faff.valuecommerce.ne.jp%2Fhome" target="_blank" rel="nofollow noopener"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=887394567" height="1" width="0" border="0" />15.6インチ　スタンダードノート</a>
    </SmallBox>
    <h3 {...{
      "id": "DAIVのノートパソコン",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#DAIV%E3%81%AE%E3%83%8E%E3%83%BC%E3%83%88%E3%83%91%E3%82%BD%E3%82%B3%E3%83%B3",
        "aria-label": "DAIVのノートパソコン permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`DAIVのノートパソコン`}</h3>
    <p>{`スタンダードノート以外はゲーミングのG-Tuneや、通常のデスクトップなど満遍なく売れているが、`}<strong parentName="p"><em parentName="strong">{`やや一歩抜き出た印象があるのがDAIV`}</em></strong>{`である。`}</p>
    <p>{`写真編集やAdobeでの作業をサクサク行いたい、あるいはレンダリングやRAW現像といった高負荷な作業を行いたいといった場合に使われるマウスコンピュータのサブブランドがDAIVである。`}</p>
    <p>{`クリエイター志向な人へ向けた作成されたパソコンのシリーズはそれほど種類は多くないが、その中で確固たる地位を築いてきたと言えるだろう。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`写真や映像編集を趣味にしている人にとっては引っかかりなく作業ができるためおすすめ`}</em></strong>{`のパソコンである。`}</p>
    <SmallBox type="word accent" text="製品ページへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886902208" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886902208" height="1" width="1" border="0" alt="" />DAIV</a>
    </SmallBox>
    <h2 {...{
      "id": "マウスコンピュータの３大悪評",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%81%AE%EF%BC%93%E5%A4%A7%E6%82%AA%E8%A9%95",
        "aria-label": "マウスコンピュータの３大悪評 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスコンピュータの３大悪評`}</h2>
    <p>{`悪評は購入時の期待値と実際の乖離から生じるものである。よって`}<strong parentName="p"><em parentName="strong">{`購入前に齟齬が出そうな点をあらかじめ抑えておくことが重要`}</em></strong>{`となる。ここではネットでの評判を抽出し、その妥当性についてコメントしていく。`}</p>
    <h3 {...{
      "id": "価格が高い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BE%A1%E6%A0%BC%E3%81%8C%E9%AB%98%E3%81%84",
        "aria-label": "価格が高い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`価格が高い`}</h3>
    <p>{`スペックに対して価格が高いということだが、確かにDellやLenovoに比べると価格競争力では劣り、同じBTOメーカーであっても例えばパソコン工房の方が比べると割安な価格を提示してきている場合が多い。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`海外と比べると国内生産によるサポート体制の充実が、パソコン工房と比べるとブランド力とノートパソコンの製品力がマウスコンピュータは優れている`}</em></strong>{`と感じる。`}</p>
    <p>{`逆にデスクトップパソコンが欲しく、パソコン好きなややマニアな層であればパソコン工房やフロンティア等のメーカーから購入すると良いだろう。機種にもよるがこちらの方が安く買える可能性は高い。`}</p>
    <h3 {...{
      "id": "故障が多い",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%95%85%E9%9A%9C%E3%81%8C%E5%A4%9A%E3%81%84",
        "aria-label": "故障が多い permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`故障が多い`}</h3>
    <p>{`初期不良の口コミが多いものの心配には当たらない。偶然不具合に当たった人が不満を述べているに過ぎない。`}</p>
    <p>{`特にユーザー数が多いパソコンは不満がネット上に多くても仕方がないであろう。また、初期不良の対応及び、メーカーの一年保証は有効である。`}</p>
    <p>{`電子機器の故障率は使用して１年目までは故障率が高く、２年から数年間は低い、そしてその後製品寿命から再び故障率が上昇する。故障率のグラフを書くとちょうどバスタブ(浴槽)のようになるため`}<em parentName="p">{`バスタブカーブ`}</em>{`とも呼ばれている。`}</p>
    <p>{`1ヵ月もしない打ちに動作が不安定になりましたなどの書き込みもあるが、`}<strong parentName="p"><em parentName="strong">{`電子機器とはそういうものだと知り冷静に対処すべき`}</em></strong>{`である。`}</p>
    <h3 {...{
      "id": "サポートが最悪",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%B5%E3%83%9D%E3%83%BC%E3%83%88%E3%81%8C%E6%9C%80%E6%82%AA",
        "aria-label": "サポートが最悪 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`サポートが最悪`}</h3>
    <p>{`マウスコンピュータはサポートが最悪というネットへの書き込みは少なからずある。ただパソコンの難しい質問に答えられなかった、オペレータの対応が悪かったなどを`}<strong parentName="p"><em parentName="strong">{`腹いせに書き込んでいるだけ`}</em></strong>{`である。`}</p>
    <p>{`返品交換ではなく、修理に回されてムカついたなどというコメントもあった。対応する側が人である以上均質な対応を求めることは不可能であり、対応には当たりはずれもあるので、`}<strong parentName="p"><em parentName="strong">{`鵜呑みにはしないことが重要`}</em></strong>{`である。`}</p>
    <h2 {...{
      "id": "マウスコンピュータの評判まとめ",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%83%9E%E3%82%A6%E3%82%B9%E3%82%B3%E3%83%B3%E3%83%94%E3%83%A5%E3%83%BC%E3%82%BF%E3%81%AE%E8%A9%95%E5%88%A4%E3%81%BE%E3%81%A8%E3%82%81",
        "aria-label": "マウスコンピュータの評判まとめ permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`マウスコンピュータの評判まとめ`}</h2>
    <p>{`以上、`}<strong parentName="p"><em parentName="strong">{`安心感を兼ねそろえつつリーゾナブルにパソコンを購入したいという人に絶大な人気を誇るメーカー`}</em></strong>{`である。商品のラインナップの確認や購入を検討している場合は下記から公式サイトへ飛ぶことができる。`}</p>
    <SmallBox type="word" text="公式サイトへ" mdxType="SmallBox">
      <a href="//ck.jp.ap.valuecommerce.com/servlet/referral?sid=2428064&pid=886847755" rel="nofollow noopener" target="_blank"><img src="//ad.jp.ap.valuecommerce.com/servlet/gifbanner?sid=2428064&pid=886847755" height="1" width="1" border="0" alt="" />マウスコンピューター</a>
    </SmallBox>
    <h2 {...{
      "id": "他社との比較",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E4%BB%96%E7%A4%BE%E3%81%A8%E3%81%AE%E6%AF%94%E8%BC%83",
        "aria-label": "他社との比較 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`他社との比較`}</h2>
    <p>{`パソコンの種類や用途により得意なメーカーは分かれるため、当サイトでは選択肢に応じたランキングを制作している。`}</p>
    <p>{`デフォルトは当サイトの人気順であるが、探している種類やこだわりがあれば選択し、自分にあったパソコンを探してほしい。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`マウスコンピュータと似ているパソコンとしてはパソコン工房やゲーミングPCならフロンティアを同時に確認したい`}</em></strong>{`。リンクをクリックすると公式サイトへ移動します。`}</p>
    <MakerRanking highlight='mouse' mdxType="MakerRanking" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      